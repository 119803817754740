import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../utils/helpers/authHelpers";
import { fetchTutors, selectTutorById } from "../../features/tutors/tutorSlice";
import { useEffect, useState } from "react";
import { Registers } from "./Registers";
import { Link } from "react-router-dom";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
const Tutors = ({ tutors }) => {
  const pageSize = 20;
  const total = tutors.length;

  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  // Calculate total number of pages
  const totalPages = Math.ceil(total / pageSize);

  // Calculate "from" and "to" values for the current page
  const from = (currentPage - 1) * pageSize + 1;
  const to = Math.min(currentPage * pageSize, total);

  const handleNextPage = () => {
    if (currentPage * pageSize < total) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    // console.log("Prev currentPage", currentPage);
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  // Handle search with debouncing
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    debounceSearch(e.target.value);
  };

  const debounceSearch = debounce((searchTerm) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1); // Reset to page 1 when search changes
  }, 300); // 300ms debounce time

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="px-4 sm:px-6 lg:px-8 py-8 bg-white border border-gray-200 rounded-lg shadow-sm">
          <div className="pb-5 space-x-3 sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex-auto">
              <h1 className="text-lg font-semibold text-gray-900">Tutors</h1>
            </div>
            <div className="grid grid-rows-3 grid-flow-col  justify-items-end "></div>

            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <input
                type="search"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search Tutor..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="mt-8 flow-root">
            {tutors.length !== 0 ? (
              <div>
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 bg-none"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                          >
                            Phone Number
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-right text-sm font-semibold text-gray-900 bg-none"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {tutors.map((item, index) => (
                          <tr key={item.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                              {index + 1}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {item.name}
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {item.msisdn}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {item.email}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              <Link
                                to="/admin/tutor-details"
                                state={{ tutorId: item.id }}
                                className="mx-2 rounded-md border border-transparent bg-blue-700  px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="flex flex-1 justify-between sm:hidden">
                    <button
                      // href="/"
                      onClick={handlePrevPage}
                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Previous
                    </button>
                    <button
                      // href="/"
                      onClick={handleNextPage}
                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Next
                    </button>
                  </div>
                  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{from}</span> to{" "}
                        <span className="font-medium"> {to}</span> of
                        <span className="font-medium"> {total}</span>
                      </p>
                    </div>
                    <div>
                      <nav
                        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination"
                      >
                        <button
                          type="button"
                          onClick={handlePrevPage}
                          disabled={currentPage === 1}
                          // onClick={() => {
                          //   if (currentPage <= 1) {
                          //     return;
                          //   } else {
                          //     setCurrentPage((prev) => prev - 1);
                          //   }
                          // }}
                          className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          <span className="sr-only">Previous</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        {/* Array(Math.round(registrations.length / itemsPerPage)) */}
                        {Array.from(
                          Array(total > 1 ? Math.round(total / pageSize) : 1)
                        )
                          .map((e, i) => i + 1)
                          .map((val, idx) => {
                            return (
                              <button
                                type="button"
                                key={idx}
                                onClick={() => setCurrentPage(val)}
                                aria-current="page"
                                className={
                                  currentPage === val
                                    ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                }
                              >
                                {val}
                              </button>
                            );
                          })}
                        <button
                          type="button"
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                          className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          <span className="sr-only">Next</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p>No Tutors Found</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Students = ({ students }) => {
  const pageSize = 20;
  const total = students.length;

  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pageBounds, setPageBounds] = useState({
    from: 0,
    to: 0,
  });
  // Calculate total number of pages
  const totalPages = Math.ceil(total / pageSize);

  useEffect(() => {
    // Calculate "from" and "to" values for the current page
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, total);
    setPageBounds((prev) => ({ ...prev, from: from, to: to }));
    setDataToDisplay(students.slice(from, to));
  }, [currentPage, students, total]);

  const handleNextPage = () => {
    if (currentPage * pageSize < total) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    // console.log("Prev currentPage", currentPage);
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  // Handle search with debouncing
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    debounceSearch(e.target.value);
  };

  const debounceSearch = debounce((searchTerm) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1); // Reset to page 1 when search changes
  }, 300); // 300ms debounce time

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="px-4 sm:px-6 lg:px-8 py-8 bg-white border border-gray-200 rounded-lg shadow-sm">
          <div className="pb-5 space-x-3 sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex-auto">
              <h1 className="text-lg font-semibold text-gray-900">Students</h1>
            </div>
            <div className="grid grid-rows-3 grid-flow-col  justify-items-end "></div>

            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <input
                type="search"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search student..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="mt-8 flow-root">
            {dataToDisplay.length !== 0 ? (
              <div>
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 bg-none"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                          >
                            Phone Number
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                          >
                            Registration No.
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-right text-sm font-semibold text-gray-900 bg-none"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {dataToDisplay
                          .filter((item) => {
                            return searchTerm.toLowerCase() === ""
                              ? item
                              : item.name.toLowerCase().includes(searchTerm) ||
                                  item.msisdn.includes(searchTerm) ||
                                  item.registration_no
                                    .toLowerCase()
                                    .includes(searchTerm);
                          })
                          .map((item, index) => (
                            <tr key={item.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                                {index + 1}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.name}
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.msisdn}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.registration_no}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                <Link
                                  to="/admin/student-details"
                                  state={{ tutorId: item.id }}
                                  className="mx-2 rounded-md border border-transparent bg-blue-700  px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="flex flex-1 justify-between sm:hidden">
                    <button
                      // href="/"
                      onClick={handlePrevPage}
                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Previous
                    </button>
                    <button
                      // href="/"
                      onClick={handleNextPage}
                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Next
                    </button>
                  </div>
                  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-700">
                        Showing{" "}
                        <span className="font-medium">{pageBounds.from}</span>{" "}
                        to <span className="font-medium"> {pageBounds.to}</span>{" "}
                        of
                        <span className="font-medium"> {total}</span>
                      </p>
                    </div>
                    <div>
                      <nav
                        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination"
                      >
                        <button
                          type="button"
                          onClick={handlePrevPage}
                          disabled={currentPage === 1}
                          // onClick={() => {
                          //   if (currentPage <= 1) {
                          //     return;
                          //   } else {
                          //     setCurrentPage((prev) => prev - 1);
                          //   }
                          // }}
                          className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          <span className="sr-only">Previous</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        {/* Array(Math.round(registrations.length / itemsPerPage)) */}
                        {Array.from(
                          Array(total > 1 ? Math.round(total / pageSize) : 1)
                        )
                          .map((e, i) => i + 1)
                          .map((val, idx) => {
                            return (
                              <button
                                type="button"
                                key={idx}
                                onClick={() => setCurrentPage(val)}
                                aria-current="page"
                                className={
                                  currentPage === val
                                    ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                }
                              >
                                {val}
                              </button>
                            );
                          })}
                        <button
                          type="button"
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                          className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          <span className="sr-only">Next</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p>No Students Found</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const TutorHome = () => {
  const [data, setData] = useState({
    sessions: [],
    tutors: [],
    students: [],
  });
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("registers");

  const user = getUserDetails();
  const tutorId = user.id;

  const tutor = useSelector(selectTutorById(tutorId));

  // let today = new Date();

  // var day = today
  //   .toLocaleDateString(undefined, {
  //     weekday: "long",
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   })
  //   .split(",")[0];

  useEffect(() => {
    if (!tutor) {
      dispatch(fetchTutors({ tutorId }));
    }
  }, [dispatch, tutor, tutorId]);

  useEffect(() => {
    if (tutor) {
      const sessions = tutor.sessions || [];
      const tutors = sessions.map((item) => item.tutor);
      const students = sessions.flatMap((item) => item.students);
      setData({ sessions, tutors, students });
    }
  }, [activeTab, tutor]);
  return (
    <>
      <section className="justify-items-center flex justify-center overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-4 lg:px-8 lg:pb-24 lg:pt-0">
        <div className="w-full">
          <div className="mt-6 gap-x-4 gap-y-4">
            <div className="col-span-full my-4">
              <nav className="flex space-x-6" aria-label="Tabs">
                <button
                  className={
                    activeTab === "registers"
                      ? "bg-gray-200 rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                      : "rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                  }
                  onClick={() => setActiveTab("registers")}
                >
                  Attendance
                </button>
                <button
                  className={
                    activeTab === "teachers"
                      ? "bg-gray-200 rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                      : "rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                  }
                  onClick={() => setActiveTab("teachers")}
                >
                  Teachers
                </button>
                <button
                  className={
                    activeTab === "students"
                      ? "bg-gray-200 rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                      : "rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                  }
                  onClick={() => setActiveTab("students")}
                >
                  Students
                </button>
              </nav>
            </div>
            <hr className="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            {activeTab === "registers" && <Registers tutorId={tutorId} />}
            {activeTab === "teachers" && <Tutors tutors={data.tutors} />}
            {activeTab === "students" && <Students students={data.students} />}
          </div>
        </div>
      </section>
    </>
  );
};
