import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchSessions,
  selectSessions,
  selectSessionsStatus,
  selectCurrentPage,
  selectPageSize,
  selectTotal,
  selectSearchTerm,
  setCurrentPage,
  setSearchTerm,
} from "./sessionSlice";
import { AssignModal } from "./AssignModal";
import { EditModal } from "./EditModal";
import { DeleteSession } from "./DeleteModal";

// Utility for debouncing search input
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const Sessions = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const currentPage = useSelector(selectCurrentPage);
  const pageSize = useSelector(selectPageSize);
  const total = useSelector(selectTotal);
  const searchTerm = useSelector(selectSearchTerm);
  const status = useSelector(selectSessionsStatus);
  const [localSearchParam, setLocalSearchParam] = useState(searchTerm);
  const dispatch = useDispatch();

  const sessions = useSelector(selectSessions);

  const totalPages = Math.ceil(total / pageSize);

  // Calculate "from" and "to" values for the current page
  const from = (currentPage - 1) * pageSize + 1;
  const to = Math.min(currentPage * pageSize, total);

  const handleNextPage = () => {
    if (currentPage * pageSize < total) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const handlePrevPage = () => {
    console.log("Prev currentPage", currentPage);
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };
  // Handle search with debouncing
  const handleSearchChange = (e) => {
    setLocalSearchParam(e.target.value);
    debounceSearch(e.target.value);
  };

  const debounceSearch = debounce((searchTerm) => {
    dispatch(setSearchTerm(searchTerm));
    dispatch(setCurrentPage(1)); // Reset to page 1 when search changes
  }, 300); // 300ms debounce time

  const handleClick = (id) => {
    // console.log("sessId", e.target.value);
    setSessionId(id);
    setShowModal(true);
  };
  const showEdit = (id) => {
    // console.log("sessId", e.target.value);
    setSessionId(id);
    setShowEditModal(true);
  };

  const deleteSession = (id) => {
    setSessionId(id);
    setConfirmDelete(true);
  };

  useEffect(() => {
    // if (status === "idle") {
    dispatch(
      fetchSessions({
        page: currentPage,
        pageSize: pageSize,
        search: searchTerm,
      })
    );
    // }
  }, [dispatch, currentPage, pageSize, searchTerm]);
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <main className="mx-auto max-w-full lg:gap-x-16 lg:px-8">
          {showModal && (
            <AssignModal
              show={showModal}
              handleClose={() => setShowModal(false)}
              sessionId={sessionId}
            />
          )}
          {showEditModal && (
            <EditModal
              show={showEditModal}
              handleClose={() => setShowEditModal(false)}
              sessionId={sessionId}
            />
          )}
          {confirmDelete && (
            <DeleteSession
              show={confirmDelete}
              handleClose={() => setConfirmDelete(false)}
              sessionId={sessionId}
            />
          )}

          <div className="sm:flex sm:items-center">
            <div className="flex items-start justify-between w-full my-3">
              <div className="sm:flex-auto">
                <h1 className="text-lg font-semibold text-gray-900">
                  Class Sessions
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  All class sessions. Add, Search, Edit and Delete sessions
                </p>
              </div>
              <div className="sm:flex-none">
                <input
                  type="search"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  placeholder="Search by class or course ..."
                  value={localSearchParam}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end my-5">
            <Link
              to="/admin/add-session"
              className="p-2 rounded-md border border-transparent bg-sky-950 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
              onClick={() => {}}
            >
              Add New
            </Link>
          </div>

          {status === "pending" ? (
            <div className="card bg-white  mx-auto w-1/2 lg:flex lg:gap-x-16 lg:px-8">
              <main className="px-1 sm:px-6 lg:flex-auto lg:px-0 lg:py-3 pb-4 pt-4">
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-5 bg-slate-400 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-4 bg-slate-400 rounded col-span-2"></div>
                        <div className="h-4 bg-slate-400 rounded col-span-1"></div>
                      </div>
                      <div className="h-3 bg-slate-400 rounded"></div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          ) : sessions?.length !== 0 ? (
            <div>
              <div className="max-h-screen overflow-auto">
                {sessions?.map((session, index) => (
                  <div
                    className="px-5 border border-gray-200 rounded-xl my-3"
                    key={session?.id}
                  >
                    <div className="py-3 flex flex-col sm:flex-row justify-between items-center">
                      {/* <h1 className="text-2xl font-semibold text-gray-900">
                      Session Details
                    </h1> */}
                      {/* <Link
                      to="/admin/class-session-info"
                      state={{ sessionId: session.id }}
                      className="rounded-md  bg-blue-600 text-white text-sm font-medium px-4 py-2 hover:bg-gray-500"
                    >
                      View Details
                    </Link> */}
                    </div>
                    <div className="flex flex-col sm:flex-row justify-between items-center">
                      <div className="flex flex-col sm:flex-row justify-between items-center">
                        <h1 className="text-xl mx-3 font-medium text-gray-900">
                          Course:
                        </h1>
                        <h1 className="text-base font-base text-gray-900">
                          {session?.course}
                        </h1>
                      </div>
                      <div className="flex flex-col sm:flex-row justify-between items-center">
                        <h1 className="text-xl mx-3 font-medium text-gray-900">
                          Class:
                        </h1>
                        <h1 className="text-base font-base text-gray-900">
                          {session?.class}
                        </h1>
                      </div>
                      <div className="flex flex-col sm:flex-row justify-between items-center space-x-3">
                        <button
                          // href="course_edit_details.html"
                          type="submit"
                          className="rounded-md border border-gray-200 text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                          onClick={() => handleClick(session?.id)}
                        >
                          Assign Tutor
                        </button>
                        <button
                          // href="course_edit_details.html"
                          type="submit"
                          className="rounded-md border bg-blue-600 text-sm font-medium text-white px-4 py-2 hover:bg-gray-400"
                          onClick={() => showEdit(session?.id)}
                        >
                          Edit
                        </button>
                        <button
                          // href="course_edit_details.html"
                          type="submit"
                          className="rounded-md border bg-red-500 text-sm font-medium text-white px-4 py-2 hover:bg-red-800"
                          onClick={() => deleteSession(session.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mt-4">
                      <dl className="mt-2 divide-y divide-gray-200 border-t border-gray-200">
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Day</dt>
                          <dd className="text-gray-900">{session?.day}</dd>
                        </div>
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Start Time</dt>
                          <dd className="text-gray-900">{session?.start_at}</dd>
                        </div>
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">End Time</dt>
                          <dd className="text-gray-900">{session?.end_at}</dd>
                        </div>
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Tutors</dt>
                          <dd className="text-gray-900">
                            {session?.tutor ? session?.tutor : "Not Assigned"}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="flex flex-1 justify-between sm:hidden">
                  <button
                    // href="/"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  <button
                    href="/"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </button>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">{from}</span> to{" "}
                      <span className="font-medium"> {to}</span> of
                      <span className="font-medium"> {total}</span>
                    </p>
                  </div>
                  <div>
                    <nav
                      className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                      aria-label="Pagination"
                    >
                      <button
                        type="button"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        // onClick={() => {
                        //   if (currentPage <= 1) {
                        //     return;
                        //   } else {
                        //     setCurrentPage((prev) => prev - 1);
                        //   }
                        // }}
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Previous</span>
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      {/* Array(Math.round(registrations.length / itemsPerPage)) */}
                      {total >= 1 &&
                        Array.from(Array(Math.round(total / pageSize)))
                          .map((e, i) => i + 1)
                          .map((val, idx) => {
                            return (
                              <button
                                type="button"
                                key={idx}
                                onClick={() => dispatch(setCurrentPage(val))}
                                aria-current="page"
                                className={
                                  currentPage === val
                                    ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                }
                              >
                                {val}
                              </button>
                            );
                          })}
                      <button
                        type="button"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        // onClick={() => {
                        //   if (
                        //     currentPage >=
                        //     registrations.length / itemsPerPage
                        //   ) {
                        //     console.log("exceeded");
                        //     return;
                        //   } else {
                        //     setCurrentPage((prev) => prev + 1);
                        //   }
                        // }}
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Next</span>
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>No sessions</p>
          )}
        </main>
      </div>
    </>
  );
};

export default Sessions;
