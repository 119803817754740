import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchClass,
  selectClassesStatus,
  selectClassesError,
  selectClassById,
} from "./slice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UpdateClassModal from "./UpdateClassModal";
import { AssignModal } from "../sessions/AssignModal";
import { EditModal } from "../sessions/EditModal";
import { DeleteSession } from "../sessions/DeleteModal";
import ConfirmDeleteModal from "./ConfirmDelete";
import Alert from "../../components/common/alert";
import { getUserDetails } from "../../utils/helpers/authHelpers";

const ClassComponent = () => {
  const [showUpdate, setShowUpdate] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showSessionUpdate, setShowSessionUpdate] = useState(false);
  const [confirmSessionDelete, setConfirmSessionDelete] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const {
    state: { id },
  } = useLocation();
  const { user_type } = getUserDetails();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classItem = useSelector(selectClassById(id));
  const status = useSelector(selectClassesStatus);
  const error = useSelector(selectClassesError);

  const pageSize = classItem?.sessions.length;
  const total = classItem?.sessions.length;

  const totalPages = Math.ceil(total / pageSize);

  // Calculate "from" and "to" values for the current page
  const from = (currentPage - 1) * pageSize + 1;
  const to = Math.min(currentPage * pageSize, total);

  const handleNextPage = () => {
    if (currentPage * pageSize < total) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const handlePrevPage = () => {
    console.log("Prev currentPage", currentPage);
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };
  const assignTutor = (id) => {
    // console.log("sessId", e.target.value);
    setSessionId(id);
    setShowAssignModal(true);
  };

  const sessionUpdate = (id) => {
    // console.log("sessId", e.target.value);
    setSessionId(id);
    setShowSessionUpdate(true);
  };
  const sessionDelete = ({ sessionId }) => {
    // console.log("sessId", e.target.value);
    setSessionId(sessionId);
    setConfirmSessionDelete(true);
  };

  // const deleteSession = (id) => {
  //   setSessionId(id);
  //   setConfirmSessionDelete(true);
  // };

  // console.log("fetching", status);
  useEffect(() => {
    if (!classItem) {
      dispatch(fetchClass({ params: { classId: id } }));
    }
  }, [classItem, dispatch, id]);

  if (status === "loading")
    return (
      <div className="mx-auto max-w-lg">
        <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
          <div className="flex justify-start items-start">
            <button
              onClick={() => navigate(-1) || navigate("/admin")}
              className="py-1 px-2 bg-gray-100 mr-4 rounded-md hover:bg-gray-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                />
              </svg>
            </button>{" "}
            <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white">
              Loading ...
            </h5>{" "}
          </div>
          <span className="sr-only">Loading ...</span>
        </div>
      </div>
    );
  if (status === "failed")
    return (
      <div className="mx-auto max-w-lg">
        <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
          <div className="flex justify-start items-start">
            <button
              onClick={() => navigate(-1) || navigate("/admin")}
              className="py-1 px-2 bg-gray-100 mr-4 rounded-md hover:bg-gray-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                />
              </svg>
            </button>{" "}
            <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white">
              Failed
            </h5>{" "}
          </div>
          <Alert show={true} content={error} type={status}></Alert>
        </div>
      </div>
    );
  // if (!classItem)
  //   return (
  //     <div className="mx-auto max-w-lg">
  //       <div className="relative items-ebd block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
  //         <div className="flex justify-start items-start">
  //           <button
  //             onClick={() => navigate(-1) || navigate("/admin")}
  //             className="py-1 px-2 bg-gray-100 mr-4 rounded-md hover:bg-gray-300"
  //           >
  //             <svg
  //               xmlns="http://www.w3.org/2000/svg"
  //               fill="none"
  //               viewBox="0 0 24 24"
  //               strokeWidth="1.5"
  //               stroke="currentColor"
  //               className="w-6 h-6"
  //             >
  //               <path
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //                 d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
  //               />
  //             </svg>
  //           </button>{" "}
  //           <Alert show={true} content={error} type={status}></Alert>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // if (!classItem)
  //   return ;
  return classItem ? (
    <>
      {confirmDelete && (
        <ConfirmDeleteModal
          show={confirmDelete}
          classId={classItem.id}
          handleClose={() => setConfirmDelete(false)}
          className={classItem.class_name}
        />
      )}
      {showUpdate && (
        <UpdateClassModal
          show={showUpdate}
          classId={classItem.id}
          handleClose={() => setShowUpdate(false)}
        />
      )}

      {showAssignModal && (
        <AssignModal
          show={showAssignModal}
          handleClose={() => setShowAssignModal(false)}
          sessionId={sessionId}
        />
      )}
      {showSessionUpdate && (
        <EditModal
          show={showSessionUpdate}
          handleClose={() => setShowSessionUpdate(false)}
          sessionId={sessionId}
        />
      )}
      {confirmSessionDelete && (
        <DeleteSession
          show={confirmSessionDelete}
          handleClose={() => setConfirmSessionDelete(false)}
          sessionId={sessionId}
        />
      )}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="card bg-white  mx-auto max-w-screen-2xl lg:flex lg:gap-x-16 lg:px-8">
          <main className="px-4 sm:px-6 lg:flex-auto lg:px-0 lg:py-12 pb-4 pt-4">
            <div className="px-5">
              {/* <div className="py-4 flex flex-col sm:flex-row justify-between items-center">
                <h1 className="text-2xl font-semibold text-gray-900">
                  Class Info
                </h1>
              </div> */}
              <div className="flex justify-start items-start">
                <button
                  onClick={() => navigate(-1)}
                  className="py-1 px-2 bg-gray-100 mr-4 rounded-md hover:bg-gray-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                    />
                  </svg>
                  <span className="text-center text-sm font-bold tracking-tight text-gray-900 dark:text-white">
                    Back
                  </span>
                </button>
              </div>
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {classItem.class_name}
                </h1>
                {user_type === "admin" && (
                  <div className="flex-none justify-between space-x-3">
                    <button
                      // to="/admin/edit-course"
                      // state={{ id: classItem.id }}
                      className="rounded-md text-sm font-medium text-white bg-blue-600 px-4 py-1.5 hover:bg-blue-800"
                      onClick={() => setShowUpdate(true)}
                    >
                      Update
                    </button>
                    <button
                      // to="/admin/edit-course"
                      // state={{ id: classItem.id }}
                      className="rounded-md text-sm font-medium text-white bg-rose-500 px-4 py-1.5 hover:bg-rose-800"
                      onClick={() => setConfirmDelete(true)}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
              <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mt-4">
                <dl className="mt-2 divide-y divide-gray-200 border-t border-gray-200">
                  <div className="flex justify-between py-3 text-sm font-medium">
                    <dt className="text-gray-700">Class Name</dt>
                    <dd className="text-gray-900">{classItem.class_name}</dd>
                  </div>
                  <div className="flex justify-between py-3 text-sm font-medium">
                    <dt className="text-gray-700">Course</dt>
                    <dd className="text-gray-900">{classItem.course}</dd>
                  </div>
                  <div className="flex justify-between py-3 text-sm font-medium">
                    <dt className="text-gray-700">Date Created</dt>
                    <dd className="text-gray-900">{classItem.created_at}</dd>
                  </div>
                </dl>
              </div>

              <div className="px-5 mt-5 border border-gray-200 rounded-xl">
                <div className="py-2 flex flex-col sm:flex-row justify-between items-center">
                  <h1 className="text-2xl my-2 font-semibold text-gray-900">
                    Sessions
                  </h1>
                  <div className="py-2 flex flex-col sm:flex-row justify-between items-center">
                    <button
                      className="rounded-md text-sm font-medium text-white bg-blue-900 px-4 py-2 hover:bg-blue-800"
                      onClick={() => setShowUpdate(true)}
                    >
                      Add Session
                    </button>
                  </div>
                </div>
                {classItem.sessions?.length !== 0 && (
                  <div>
                    <div className="max-h-screen overflow-auto">
                      {classItem.sessions?.map((session, index) => (
                        <div
                          className="px-5 border border-gray-200 rounded-xl my-3"
                          key={session?.id}
                        >
                          <div className="py-3 flex flex-col sm:flex-row justify-between items-center">
                            {/* <h1 className="text-2xl font-semibold text-gray-900">
                      Session Details
                    </h1> */}
                            {/* <Link
                      to="/admin/class-session-info"
                      state={{ sessionId: session.id }}
                      className="rounded-md  bg-blue-600 text-white text-sm font-medium px-4 py-2 hover:bg-gray-500"
                    >
                      View Details
                    </Link> */}
                          </div>
                          <div className="flex flex-col sm:flex-row justify-between items-center">
                            <div className="flex flex-col sm:flex-row justify-between items-center">
                              <h1 className="text-xl mx-3 font-medium text-gray-900">
                                Course:
                              </h1>
                              <h1 className="text-base font-base text-gray-900">
                                {session?.course}
                              </h1>
                            </div>
                            <div className="flex flex-col sm:flex-row justify-between items-center">
                              <h1 className="text-xl mx-3 font-medium text-gray-900">
                                Class:
                              </h1>
                              <h1 className="text-base font-base text-gray-900">
                                {session?.class}
                              </h1>
                            </div>
                            <div className="flex flex-col sm:flex-row justify-between items-center space-x-3">
                              <button
                                // href="course_edit_details.html"
                                type="submit"
                                className="rounded-md border border-gray-200 text-sm font-medium text-white bg-emerald-500 px-4 py-2 hover:bg-emerald-800"
                                onClick={() => assignTutor(session?.id)}
                              >
                                Assign Tutor
                              </button>
                              <button
                                // href="course_edit_details.html"
                                type="submit"
                                className="rounded-md border bg-blue-600 text-sm font-medium text-white px-4 py-2 hover:bg-gray-400"
                                onClick={() => sessionUpdate(session?.id)}
                              >
                                Update
                              </button>
                              <button
                                // href="course_edit_details.html"
                                type="submit"
                                className="rounded-md border bg-rose-500 text-sm font-medium text-white px-4 py-2 hover:bg-red-800"
                                onClick={() =>
                                  sessionDelete({ sessionId: session?.id })
                                }
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mt-4">
                            <dl className="mt-2 divide-y divide-gray-200 border-t border-gray-200">
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Day</dt>
                                <dd className="text-gray-900">
                                  {session?.day}
                                </dd>
                              </div>
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Start Time</dt>
                                <dd className="text-gray-900">
                                  {session?.start_at}
                                </dd>
                              </div>
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">End Time</dt>
                                <dd className="text-gray-900">
                                  {session?.end_at}
                                </dd>
                              </div>
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Tutors</dt>
                                <dd className="text-gray-900">
                                  <Link
                                    to="/admin/tutor-details"
                                    state={{ id: session.tutor?.tutor_id }}
                                    className="text-blue-700 px-2 py-1 text-sm font-medium hover:text-gray-700"
                                  >
                                    {session?.tutor
                                      ? session?.tutor
                                      : "Not Assigned"}
                                  </Link>
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="flex flex-1 justify-between sm:hidden">
                        <button
                          // href="/"
                          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                          Previous
                        </button>
                        <button
                          href="/"
                          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                          Next
                        </button>
                      </div>
                      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div>
                          <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{from}</span>{" "}
                            to <span className="font-medium"> {to}</span> of
                            <span className="font-medium"> {total}</span>
                          </p>
                        </div>
                        <div>
                          <nav
                            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                            aria-label="Pagination"
                          >
                            <button
                              type="button"
                              onClick={handlePrevPage}
                              disabled={currentPage === 1}
                              // onClick={() => {
                              //   if (currentPage <= 1) {
                              //     return;
                              //   } else {
                              //     setCurrentPage((prev) => prev - 1);
                              //   }
                              // }}
                              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                              <span className="sr-only">Previous</span>
                              <svg
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            {/* Array(Math.round(registrations.length / itemsPerPage)) */}
                            {total >= 1 &&
                              Array.from(Array(Math.round(total / pageSize)))
                                .map((e, i) => i + 1)
                                .map((val, idx) => {
                                  return (
                                    <button
                                      type="button"
                                      key={idx}
                                      onClick={() =>
                                        dispatch(setCurrentPage(val))
                                      }
                                      aria-current="page"
                                      className={
                                        currentPage === val
                                          ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                          : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                      }
                                    >
                                      {val}
                                    </button>
                                  );
                                })}
                            <button
                              type="button"
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                              // onClick={() => {
                              //   if (
                              //     currentPage >=
                              //     registrations.length / itemsPerPage
                              //   ) {
                              //     console.log("exceeded");
                              //     return;
                              //   } else {
                              //     setCurrentPage((prev) => prev + 1);
                              //   }
                              // }}
                              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                              <span className="sr-only">Next</span>
                              <svg
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {classItem.sessions?.length !== 0 ? (
                  <div className="flex-none min-w-full px-4 sm:px-6 md:px-0 overflow-hidden lg:overflow-auto scrollbar:!w-1.5 scrollbar:!h-1.5 scrollbar:bg-transparent scrollbar-track:!bg-slate-100 scrollbar-thumb:!rounded scrollbar-thumb:!bg-slate-300 scrollbar-track:!rounded dark:scrollbar-track:!bg-slate-500/[0.16] dark:scrollbar-thumb:!bg-slate-500/50 max-h-96 lg:supports-scrollbars:pr-2 lg:max-h-96">
                    {classItem.sessions?.map((session) => (
                      <div
                        key={session.id}
                        className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mt-4"
                      >
                        <dl className="mt-2 divide-y divide-gray-200 border-t border-gray-200">
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Day</dt>
                            <dd className="text-gray-900">{session.day}</dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Start Time</dt>
                            <dd className="text-gray-900">
                              {session.start_at}
                            </dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">End Time</dt>
                            <dd className="text-gray-900">{session.end_at}</dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Tutor</dt>
                            <dd className="text-gray-900">
                              {session.tutor?.name}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="py-2 flex flex-col sm:flex-row justify-between items-center">
                    <h1 className="text-base text-gray-900">No Sessions</h1>
                    <button
                      className="rounded-md text-sm font-medium text-white bg-blue-600 px-4 py-2 hover:bg-blue-800"
                      onClick={() => setShowUpdate(true)}
                    >
                      Add
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  ) : status === "success" ? (
    <div className="mx-auto max-w-lg">
      <div className="relative items-ebd block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
        <div className="flex justify-start items-start">
          <button
            onClick={() => navigate(-1)}
            className="py-1 px-2 bg-gray-100 mr-4 rounded-md hover:bg-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
              />
            </svg>
            <span className="text-center text-sm font-bold tracking-tight text-gray-900 dark:text-white">
              Back
            </span>
          </button>
          <Alert content={error} type="success"></Alert>
        </div>
      </div>
    </div>
  ) : (
    <div className="mx-auto max-w-lg">
      <div className="relative items-ebd block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
        <div className="flex justify-start items-start">
          <button
            onClick={() => navigate(-1) || navigate("/admin")}
            className="py-1 px-2 bg-gray-100 mr-4 rounded-md hover:bg-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
              />
            </svg>
          </button>{" "}
          <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white">
            Class not found
          </h5>{" "}
        </div>
      </div>
    </div>
  );
};

export default ClassComponent;
