import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Apis from "../../utils/api/api";
import { setAuth, setUserDetails } from "../../utils/helpers/authHelpers";

import Alert from "../../components/common/alert";
import {
  setLogin,
  // selectAuth,
  setAuthType,
} from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";

export const Login = () => {
  const [msisdn, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [authStatus, setAuthStatus] = useState(false);
  const [err, setErr] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const loginUser = async (data) => {
    try {
      const { data: response } = await Apis.login(data);
      const authType = response.user.user_type;

      setAuth({ token: response.access.token });
      setUserDetails(response.user);
      dispatch(setLogin(true));
      dispatch(setAuthType(authType));
      setAuthStatus("success");
      setErr("Login in ...");
      setShowAlert(true);
      navigate("/dashboard");

      // switch (authType) {
      //   case "admin":
      //     setAuth({ token: response.access.token });
      //     setUserDetails(response.user);
      //     dispatch(setLogin(true));
      //     dispatch(setAuthType(authType));
      //     setAuthStatus("success");
      //     setErr("Login in ...");
      //     setShowAlert(true);
      //     navigate("/admin");
      //     break;
      //   case "tutor":
      //     setAuth({ token: response.access.token });
      //     setUserDetails(response.user);
      //     dispatch(setLogin(true));
      //     dispatch(setAuthType(authType));
      //     setAuthStatus("success");
      //     setErr("Login in ...");
      //     setShowAlert(true);
      //     navigate("/admin");
      //     break;
      //   case "cashier":
      //     setAuth({ token: response.access.token });
      //     setUserDetails(response.user);
      //     dispatch(setLogin(true));
      //     setAuthStatus("success");
      //     setErr("Login in ...");
      //     setShowAlert(true);
      //     navigate("/admin");
      //     break;
      //   default:
      //     navigate("/auth/login");
      //     break;
      // }
    } catch (error) {
      console.log("LoginError", error);
      const { message: response } = error;
      setAuthStatus("failed");
      setErr(`${response}. Contact admin for assistance`);
      setShowAlert(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (msisdn && password) {
      loginUser({ msisdn, password });
    } else {
      // console.log("No data supplied");
      setShowAlert(true);
      // setErr("Phone Number and password required");
    }
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 6000);
    }
  }, [showAlert]);

  return (
    <>
      {/* <!-- Checkout form --> */}
      <section
        aria-labelledby="payment-heading"
        className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
      >
        <div className="mx-auto max-w-lg">
          <div className="mx-auto max-w-lg">
            <div className="pb-5 pt-12 lg:flex lg:justify-center lg:items-center lg:h-45">
              <a href="/">
                <span className="sr-only">IYF Free Academy - Login</span>
                <img
                  src="../assets/png/iyflogo-s8.png"
                  alt=""
                  className="h-40 w-auto"
                ></img>
              </a>
            </div>
          </div>
          {showAlert && (
            <Alert show={showAlert} content={err} type={authStatus}></Alert>
          )}
          <form className="mt-6" onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-x-4 gap-y-4">
              <div className="col-span-full border border-gray-200 rounded-xl shadow-sm">
                <div className="p-3 bg-white">
                  <label
                    htmlFor="msisdn"
                    className="block text font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="msisdn"
                      id="msisdn"
                      autoComplete="Phone Number"
                      placeholder="0712345678"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      onChange={handlePhoneNumberChange}
                      required
                    ></input>
                  </div>
                </div>
                <div className="p-3 bg-white">
                  <label
                    htmlFor="password"
                    className="block text font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      onChange={handlePasswordChange}
                      required
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Login
              </button>
              <Link
                to="/forgot-password"
                className="mt-6 text-sm font-medium text-blue-600 hover:text-blue-800 "
              >
                Forgot Password?
              </Link>
            </div>

            {/* <div className="flex items-start mb-5">
            <div className="flex items-center h-5">
              <input
                id="remember"
                type="checkbox"
                value=""
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                required
              />
            </div>
            <label
              htmlFor="remember"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Remember me
            </label>
          </div> */}
          </form>
        </div>
      </section>
    </>
  );
};
