import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSession,
  selectSessionsError,
  selectSessionsStatus,
} from "./sessionSlice";
import { useEffect, useState } from "react";
import { selectClassesStatus, selectAllClasses } from "../classes/slice";
import { fetchClasses } from "../classes/slice";
import Alert from "../../components/common/alert";

export const DeleteSession = ({ show, handleClose, sessionId }) => {
  const [alert, setAlert] = useState({
    show: false,
    msg: "",
    type: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useSelector(selectAllClasses);
  const classStatus = useSelector(selectClassesStatus);

  const status = useSelector(selectSessionsStatus);
  const error = useSelector(selectSessionsError);
  const handleDelete = async () => {
    try {
      await dispatch(deleteSession({ id: sessionId })).unwrap();
      console.log("error", error);
      setAlert({
        ...alert,
        show: true,
        msg: "Session deleted succeessfully",
        type: "success",
      });
    } catch (error) {
      console.log("Error", error, error);
      setAlert({
        ...alert,
        show: true,
        msg: "Something went wrong. Could not delete session at the moment. Try again later",
        type: "failed",
      });
    }

    // .then(() => {
    //   // Navigate back to the list or another page after successful deletion
    //   setAlertMsg({
    //     ...alertMsg,
    //     msg: "Session deleted successfully",
    //   });
    //   setTimeout(() => {
    //     handleClose();
    //     navigate("/admin/sessions");
    //   }, 3000);
    // })
    // .catch((err) => {
    //   console.error("Failed to delete session: ", err);
    // });
  };

  useEffect(() => {
    console.log("Fired!", error);
    if (alert.show) {
      setTimeout(() => {
        setAlert((prev) => ({ ...prev, show: false }));
      }, 5000);
    }
  }, [alert.show]);
  return (
    <>
      {show && (
        <div
          className=" relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
            aria-hidden="true"
          ></div>

          <div className="fixed inset-0 z-1 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative size-1/4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto text-center">
                      <h1 className="text-lg font-semibold text-gray-900">
                        Are you sure you want to delete session?
                      </h1>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <button
                        type="button"
                        className="mt-2 justify-center py-2 text-sm font-semibold text-white ring-gray-300 sm:mt-0 sm:w-auto"
                        onClick={handleClose}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6 rounded-md bg-red-500 hover:bg-red-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <hr className="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  {/* <AddSessionForm session={session} /> */}
                  <div className="flex justify-center col-span-full">
                    {alert.show && (
                      <Alert
                        show={alert.show}
                        content={alert.msg}
                        type={alert.type}
                      ></Alert>
                    )}
                  </div>

                  <div className="flex justify-between">
                    <button
                      type="submit"
                      className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="mt-6 rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
