import { useCallback, useEffect, useState } from "react";
import Alert from "../../components/common/alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  createCourse,
  fetchCourses,
  selectCourseById,
  selectCourseError,
  selectCourseStatus,
  updateCourse,
} from "./courseSlice";

export const EditCourse = () => {
  const [showClassInputs, setShowClassInputs] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    show: false,
    msg: "",
    type: "",
  });
  const [courseData, setCourseData] = useState({});
  const [classes, setClasses] = useState([""]);
  const [loading, setLoading] = useState([]);

  const status = useSelector(selectCourseStatus);
  const courseError = useSelector(selectCourseError);

  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = location.state;

  const course = useSelector(selectCourseById(id));

  // const [classInputs, setClassInputs] = useState([
  //   {
  //     name: "",
  //   },
  // ]);

  const addClassInput = () => {
    setShowClassInputs(true);
    setClasses((arr) => {
      return [...arr, ""];
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData({ ...courseData, [name]: value });
  };

  const handleClassInputChange = (e, idx) => {
    const value = e.target.value;
    const classNames = classes.map((el, i) => (idx === i ? value : el));
    // console.log("Classes", [...new Set(classNames)]);
    setCourseData({ ...courseData, classes: [...new Set(classNames)] });
    setClasses(classNames);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Classes", course.classes);
    // console.log("course>>>", course);
    try {
      await dispatch(updateCourse({ ...courseData, id })).unwrap();
      console.log("ERR", courseError);
      setAlertMsg({
        ...alertMsg,
        show: true,
        msg: "Course Updated Successfully",
      });
    } catch (error) {
      // const { data: response } = error;
      console.log("Updates Error:", error);
      setAlertMsg({
        ...alertMsg,
        msg: error,
        show: true,
      });
    } finally {
      e.target.reset();
    }
  };

  const fetchCourse = useCallback(() => {
    dispatch(fetchCourses({ courseId: id }))
      .unwrap()
      .then((results) => {
        setLoading(false);
        setCourseData(results);
      });
  }, [dispatch, id]);
  useEffect(() => {
    if (!course) {
      dispatch(fetchCourses({ params: { courseId: id } }));
    }
  }, [dispatch, course, id]);

  useEffect(() => {
    setTimeout(() => {
      setAlertMsg({ ...alertMsg, show: false });
    }, 5000);
  }, [fetchCourse, alertMsg]);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 py-8 bg-white border border-gray-200 rounded-lg shadow-sm">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-semibold text-gray-900">
              Add New Course
            </h1>
          </div>
        </div>
        {!course ? (
          <div className="mx-auto max-w-lg">
            <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
              <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                Fetching Course
              </h5>
              <div
                role="status"
                className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
              >
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading ...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-2 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <form className="mt-6" onSubmit={handleSubmit}>
                  <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                    <div className="flex justify-center  col-span-full">
                      {alertMsg.show && (
                        <Alert
                          show={alertMsg.show}
                          content={alertMsg.msg}
                          type={status}
                        ></Alert>
                      )}
                    </div>
                    <div className="col-span-full">
                      <fieldset>
                        <legend className="block text font-medium text-gray-700">
                          Course Details
                        </legend>
                        <div className="p-4 mt-3 grid grid-cols-1 gap-x-4 gap-y-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                          <div className="col-span-full sm:col-span-6">
                            <label
                              htmlFor="course_name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Course Name
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="name"
                                id="course_name"
                                placeholder="Course Name"
                                defaultValue={course.course_name}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-span-full sm:col-span-6">
                            <label
                              htmlFor="course_code"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Course Code
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="course_code"
                                id="course_code"
                                placeholder="Course Name"
                                defaultValue={course.course_code}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-span-full">
                            <label
                              htmlFor="course_details"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Course Description
                            </label>
                            <div className="mt-1">
                              <textarea
                                minLength={50}
                                maxLength={200}
                                type="text"
                                name="details"
                                id="course_details"
                                placeholder="Course Description"
                                defaultValue={course.course_details}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>

                    <div className="col-span-full">
                      <fieldset>
                        <legend className="block text font-medium text-gray-700">
                          Course Classes
                        </legend>

                        {course.classes.map((item, index) => (
                          <div
                            className="p-4 mt-3 bg-white border border-gray-200 rounded-xl shadow-sm"
                            key={index}
                          >
                            <label
                              htmlFor="payment-method"
                              className="block text-sm font-medium leading-6 text-gray-700"
                            >
                              Class Name
                            </label>
                            <input
                              id="class-title"
                              name="class_name"
                              type="text"
                              placeholder="Class Name"
                              defaultValue={item.class_name || ""}
                              onChange={(e) => handleClassInputChange(e, index)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        ))}
                        {showClassInputs &&
                          classes.map((item, index) => (
                            <div
                              className="p-4 mt-3 bg-white border border-gray-200 rounded-xl shadow-sm"
                              key={index}
                            >
                              <label
                                htmlFor="payment-method"
                                className="block text-sm font-medium leading-6 text-gray-700"
                              >
                                Class Name
                              </label>
                              <input
                                id="class-title"
                                name="class_name"
                                type="text"
                                placeholder="Class Name"
                                defaultValue={item.class_name || ""}
                                onChange={(e) =>
                                  handleClassInputChange(e, index)
                                }
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          ))}
                        <div className="flex items-end gap-x-3">
                          <div className="mt-3 space-y-2">
                            <button
                              type="button"
                              onClick={addClassInput}
                              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                            >
                              + Add Class
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className="mt-3 flex justify-between">
                    <Link
                      to="/admin/courses"
                      type="submit"
                      className="mt-3 rounded-md text-base text-blue-600 px-4 py-2 hover:bg-white"
                    >
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      className="mt-3 rounded-md bg-blue-700 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 sm:ml-3 sm:w-auto"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <div className="mx-auto max-w-lg"> */}

      {/* <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            >
              Deactivate
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            >
              Cancel
            </button>
          </div> */}
      {/* </div> */}
      {/* <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>


      </div> */}
    </>
  );
};
