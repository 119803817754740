import React from "react";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdmins,
  selectPageSize,
  selectSearchTerm,
  selectTotal,
  selectAdminsError,
  selectAdmins,
  selectAdminsStatus,
  setSearchTerm,
  updateAdmin,
} from "../../features/admin/adminSlice";
import { Link } from "react-router-dom";
import AddAdmin from "../../features/admin/addAdmin";
import Alert from "../../components/common/alert";
import { getUserDetails } from "../../utils/helpers/authHelpers";

// Utility for debouncing search input
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const ConfirmModal = ({ show, handleClose, details }) => {
  const message = useSelector(selectAdminsError);
  const status = useSelector(selectAdminsStatus);
  const [alert, setAlert] = useState({
    show: false,
    msg: message,
    type: status,
  });
  const dispatch = useDispatch();

  const handleArchive = async () => {
    try {
      await dispatch(
        updateAdmin({ id: details.id, status: details.status })
      ).unwrap();
      setAlert({
        show: true,
        msg: "Student archived successfully!",
        type: "success",
      });
    } catch (error) {
      setAlert({
        show: true,
        msg: "Student archiving failed. Try again later!",
        type: "failed",
      });
    }
  };

  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert({ show: false });
      }, 2500);
    }
  }, [alert.show]);

  return (
    <>
      {show && (
        <div
          className=" relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
            aria-hidden="true"
          ></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-6">
              <div className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full max-w-md sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex justify-end items-center mb-4">
                    {/* <div className="sm:flex-auto">
                    <h1 className="text-lg font-semibold text-gray-900">
                      Are you sure you want to delete {className}?
                    </h1>
                  </div> */}
                    <button
                      type="button"
                      className="p-2 rounded-full text-red-500 hover:bg-red-100"
                      onClick={handleClose}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  {/* <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr> */}
                  {alert.show && (
                    <Alert
                      show={alert.show}
                      content={alert.msg}
                      type={alert.type}
                    ></Alert>
                  )}
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full p-2 align-middle sm:px-6 lg:px-8">
                      <div className="sm:flex-auto">
                        <h1 className="text-lg text-center font-normal text-gray-900">
                          Are you sure you want to update {details.name} to{" "}
                          <b>{details.status}</b>?
                        </h1>
                      </div>
                      <div className="mt-3 flex justify-between">
                        <button
                          onClick={handleClose}
                          className="mt-3 rounded-md text-base text-blue-600 px-4 py-2 hover:bg-white"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="mt-3 rounded-md bg-emerald-600 p-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 sm:ml-3 sm:w-auto"
                          onClick={() => handleArchive()}
                        >
                          Yes, update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const AdminList = () => {
  const pageSize = useSelector(selectPageSize);
  const total = useSelector(selectTotal);
  const status = useSelector(selectAdminsStatus);
  const searchTerm = useSelector(selectSearchTerm);
  const { user_type } = getUserDetails();

  const dispatch = useDispatch();

  const admins = useSelector(selectAdmins);
  const [currentPage, setCurrentPage] = useState(1);
  const [localSearchParam, setLocalSearchParam] = useState(searchTerm);
  const [showModal, setShowModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState({});

  useEffect(() => {
    // if (status === "idle")
    dispatch(
      fetchAdmins({
        page: currentPage,
        pageSize: pageSize,
        search: searchTerm,
      })
    );
  }, [dispatch, currentPage, pageSize, searchTerm]);

  // Calculate total number of pages
  const totalPages = Math.ceil(total / pageSize);

  // Calculate "from" and "to" values for the current page
  const from = (currentPage - 1) * pageSize + 1;
  const to = Math.min(currentPage * pageSize, total);

  const handleNextPage = () => {
    if (currentPage * pageSize < total) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    // console.log("Prev currentPage", currentPage);
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  // Handle search with debouncing
  const handleSearch = (e) => {
    setLocalSearchParam(e.target.value);
    debounceSearch(e.target.value);
  };

  const debounceSearch = debounce((searchTerm) => {
    dispatch(setSearchTerm(searchTerm));
    setCurrentPage(1); // Reset to page 1 when search changes
  }, 300); // 300ms debounce time

  const handleSuspendAdmin = ({ id, name, status }) => {
    setConfirmInfo({ id, name, status });
    setShowConfirm(true);
  };

  return (
    <>
      {showModal && (
        <AddAdmin show={showModal} handleClose={() => setShowModal(false)} />
      )}
      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          details={confirmInfo}
          handleClose={() => setShowConfirm(false)}
        />
      )}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="px-4 sm:px-6 lg:px-8 py-8 bg-white border border-gray-200 rounded-lg shadow-sm">
          <div className="pb-5 space-x-3 sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex-auto">
              <h1 className="text-lg font-semibold text-gray-900">Admins</h1>
            </div>
            <div className="grid grid-rows-3 grid-flow-col  justify-items-end "></div>

            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <input
                type="search"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search Admin..."
                value={localSearchParam}
                onChange={handleSearch}
              />
            </div>
            {user_type === "admin" && (
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  data-modal-target="default-modal"
                  data-modal-toggle="default-modal"
                  className="rounded-md border border-transparent bg-sky-950 p-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Add New
                </button>
              </div>
            )}
          </div>
          {status === "loading" ? (
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                  Loading ...
                </h5>
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-600 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-600 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-600 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-600 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-8 flow-root">
              {admins.length !== 0 ? (
                <div>
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 bg-none"
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                            >
                              First Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                            >
                              Last Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                            >
                              Phone Number
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                            >
                              Email
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                            >
                              Date Added
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-right text-sm font-semibold text-gray-900 bg-none"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {admins.map((item, index) => (
                            <tr key={index}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                                {index + 1}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.first_name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.last_name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.user.msisdn}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.user.email}
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.status === "active" ? (
                                  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                    {item.status}
                                  </span>
                                ) : (
                                  <span className="inline-flex items-center rounded-md bg-rose-100 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                                    {item.status}
                                  </span>
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.created_at}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                {user_type === "admin" &&
                                  (item.status === "active" ? (
                                    <button
                                      // to="/admin/edit-course"
                                      // state={{ id: classItem.id }}
                                      className="rounded-md text-sm font-medium text-white bg-rose-500 px-3 py-1.5 hover:bg-blue-800"
                                      onClick={() =>
                                        handleSuspendAdmin({
                                          id: item.id,
                                          name: item.first_name,
                                          status: "inactive",
                                        })
                                      }
                                    >
                                      Suspend
                                    </button>
                                  ) : (
                                    <button
                                      // to="/admin/edit-course"
                                      // state={{ id: classItem.id }}
                                      className="rounded-md text-sm font-medium text-white bg-emerald-500 px-3 py-1.5 hover:bg-blue-800"
                                      onClick={() =>
                                        handleSuspendAdmin({
                                          id: item.id,
                                          name: item.full_name,
                                          status: "active",
                                        })
                                      }
                                    >
                                      Activate
                                    </button>
                                  ))}
                                <Link
                                  to="/admin/admin-details"
                                  state={{ adminId: item.id }}
                                  className="mx-2 rounded-md border border-transparent bg-blue-700  px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="flex flex-1 justify-between sm:hidden">
                      <button
                        // href="/"
                        onClick={handlePrevPage}
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      >
                        Previous
                      </button>
                      <button
                        // href="/"
                        onClick={handleNextPage}
                        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      >
                        Next
                      </button>
                    </div>
                    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                      <div>
                        <p className="text-sm text-gray-700">
                          Showing <span className="font-medium">{from}</span> to{" "}
                          <span className="font-medium"> {to}</span> of
                          <span className="font-medium"> {total}</span>
                        </p>
                      </div>
                      <div>
                        <nav
                          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                          aria-label="Pagination"
                        >
                          <button
                            type="button"
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            // onClick={() => {
                            //   if (currentPage <= 1) {
                            //     return;
                            //   } else {
                            //     setCurrentPage((prev) => prev - 1);
                            //   }
                            // }}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                          >
                            <span className="sr-only">Previous</span>
                            <svg
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                          {/* Array(Math.round(registrations.length / itemsPerPage)) */}
                          {Array.from(
                            Array(total > 1 ? Math.round(total / pageSize) : 1)
                          )
                            .map((e, i) => i + 1)
                            .map((val, idx) => {
                              return (
                                <button
                                  type="button"
                                  key={idx}
                                  onClick={() => setCurrentPage(val)}
                                  aria-current="page"
                                  className={
                                    currentPage === val
                                      ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                  }
                                >
                                  {val}
                                </button>
                              );
                            })}
                          <button
                            type="button"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                          >
                            <span className="sr-only">Next</span>
                            <svg
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p>No Admins</p>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminList;
