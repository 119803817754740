import axios from "axios";
import { getOidToken } from "../../helpers/authHelpers";

const BASE_URL = "https://freeacademy.iyfkenya.org/api/v1";
// const BASE_URL = "http://127.0.0.1:5000/api/v1";

export const defaultParams = () => ({
  headers: { Authorization: `Bearer ${getOidToken()}` },
});

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": BASE_URL,
  },
});
